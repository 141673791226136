
import AppInput from "@/components/Form/AppInput.vue";
import {
  defineComponent,
  PropType,
  reactive,
  Ref,
  ref,
  toRefs,
  watch,
  computed
} from "vue";

import {
  ILocationPackageListItemEditDto,
  IOptionsPlan,
  IOptionsProvider
} from "@/hooks/esim/location-packages/types/useLocationPackageListType";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import {
  IDataType,
  IPlanOperator,
  IPurchaseType,
  IValidity
} from "@/api/services/packages/esim/location-packages/types";
import SelectDataTypes from "@/components/Packages/eSIM/Directory/Selects/SelectDataTypes.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import SelectBundle from "@/components/Packages/eSIM/Directory/Selects/SelectBundle.vue";
import SelectPurchaseType from "@/components/Packages/eSIM/Directory/Selects/SelectPurchaseType.vue";
import SelectValidityType from "@/components/Packages/eSIM/Directory/Selects/SelectValidityType.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import Partner from "@/components/ui/Partner.vue";
import { networkGenerationsToString } from "@/store/modules/carriers/utills/networkGenerationsToString";
import { useCollapseAnimation } from "@/hooks/animation/useCollapseAnimation";
import { ITab } from "@/store/interfaces/common";
import TabsDialog from "@/components/ui/Modal/Dialog/TabsDialog.vue";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
import { useStore } from "vuex";

const tabs = ["Details", "Carriers"];

export default defineComponent({
  name: "EditLocationPackageDialog",
  components: {
    TabsDialog,
    Partner,
    AppButton,
    SelectValidityType,
    SelectPurchaseType,
    SelectBundle,
    SelectInput,
    SelectDataTypes,
    AppInputsValidationProvider,
    AppInput
  },
  props: {
    opened: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    operators: { type: Array as PropType<IPlanOperator[]>, default: () => [] },
    selectedLocationName: { type: String, default: "" },
    selectedLocationType: { type: String, default: "" },
    getPrice: {
      type: Function,
      required: true
    },
    editData: {
      type: Object as PropType<ILocationPackageListItemEditDto>,
      required: true
    },
    trafficList: {
      type: Array as PropType<IEsimDirectoryDefault[]>,
      required: true
    },
    providers: {
      type: Array as PropType<IOptionsProvider[]>,
      required: true
    }
  },
  emits: { hide: null, submit: null },

  setup(props) {
    const store = useStore();

    const eSimTypes = ref([
      { id: 1, name: "Bundle", selected: true },
      { id: 2, name: "PayAsYouGo", selected: false }
    ]);

    const { editData, getPrice, trafficList, providers } = toRefs(props);
    const packageModel: ILocationPackageListItemEditDto = reactive({
      ...editData.value,
      selectedLocationType: props.selectedLocationType,
      locationRates: []
    });

    watch(
      () => editData,
      data => {
        Object.entries(data.value).forEach(([key, value]) => {
          packageModel[key] = value;
          if (key === "traffic") {
            if (!value?.id) {
              packageModel[key] = trafficList.value[0];
            }
          }
          if (key === "providerType") {
            if (!value?.id) {
              packageModel[key] = providers.value[0];
            }
          }
        });

        if (data.value?.bundleId === -1) {
          eSimTypes.value.forEach((type: IOptionsPlan) => {
            type.selected = type.id === 2;
          });
        } else {
          eSimTypes.value.forEach((type: IOptionsPlan) => {
            type.selected = type.id === 1;
          });
        }
      },
      { deep: true, immediate: true }
    );

    type TDirectoryData = IDataType | IValidity | IPurchaseType | number;

    function setDirectoryData(
      key: keyof ILocationPackageListItemEditDto,
      value: TDirectoryData
    ) {
      packageModel[key] = value;
    }

    const localDefaultPrice = ref("");

    watch(
      () => packageModel.defaultPrice,
      (price: number) => {
        if (price.toString() !== localDefaultPrice.value) {
          if (!props.isEdit) {
            localDefaultPrice.value = "";

            return;
          }

          localDefaultPrice.value = price.toString();
        }
      },
      { immediate: true }
    );

    watch(
      () => props.isEdit,
      () => {
        if (!props.isEdit) {
          localDefaultPrice.value = "";
        }
      },
      { immediate: true }
    );

    watch(
      () => props.selectedLocationType,
      () => {
        packageModel.selectedLocationType = props.selectedLocationType;
      }
    );

    watch(localDefaultPrice, (price: string) => {
      if (packageModel.defaultPrice.toString() !== price) {
        packageModel.defaultPrice = Number(price) || 0;
      }
    });

    // Discount types

    const discountValue = ref(
      packageModel.discountAmount ?? packageModel.discountPercent ?? 0
    );

    const discountAvailable: Ref<boolean> = ref(packageModel.discount);
    const packageCommission: Ref<number> = ref(0);

    watch(
      () => editData.value.packageCommission,
      (price: number | null) => {
        packageCommission.value = price || 0;
      }
    );
    watch(
      () => packageCommission.value,
      price => {
        if (
          packageModel.packageCommission !== Number(price) &&
          Number(price) < 101
        ) {
          packageModel.packageCommission = Number(price) || 0;
          packageCommission.value = Number(price) || 0;
        } else if (Number(price) > 100) {
          packageModel.packageCommission = 100;
          packageCommission.value = 100;
        }
      }
    );
    const mappedTraffic = computed(() => {
      return props.trafficList.map((traffic, idx) => ({
        id: traffic.id,
        title: traffic.title,
        amount: traffic.amount,
        selected: !!packageModel.traffic?.id
          ? packageModel.traffic.id === traffic.id
          : idx === 0
      }));
    });
    const mappedProvider = computed(() => {
      return props.providers.map((pvr, idx) => ({
        id: pvr.id,
        name: pvr.name,
        selected: !!packageModel.providerType?.id
          ? packageModel.providerType.id === pvr.id
          : idx === 0
      }));
    });

    const selectedProvider = computed(() => {
      return mappedProvider.value.find(pvr => pvr.selected)?.name || "";
    });

    watch(
      packageModel,
      () => {
        if (packageModel.selectedLocationType === "countries") {
          let monthPrice = 0;
          if (packageModel.validity?.period === 365) {
            const { selectedPlan, traffic, locationId } = packageModel;
            monthPrice = store.getters.getMountPackage(
              selectedPlan,
              traffic.amount,
              locationId,
              props.selectedLocationType
            );
            if (!monthPrice) {
              localDefaultPrice.value = "Error";
              return;
            }
          }
        } else {
          packageModel.defaultPrice = Number(localDefaultPrice.value);
          if (packageModel.discount) {
            packageModel.priceWithDiscount =
              Number(localDefaultPrice.value) -
              (Number(localDefaultPrice.value) / 100) *
                packageModel.discountAmount;
          } else {
            packageModel.priceWithDiscount = Number(localDefaultPrice.value);
          }
        }
      },
      { deep: true }
    );

    const showField = computed(
      () =>
        eSimTypes.value.find(t => t.selected)?.id === 1 ||
        selectedProvider.value === "EsimGo"
    );
    return {
      packageModel,
      discountAvailable,
      discountValue,
      packageCommission,
      localDefaultPrice,
      mappedTraffic,
      mappedProvider,
      selectedProvider,
      selectedBundleId: computed(() => packageModel.bundleId || -1),
      selectedDataTypeId: computed(() => packageModel.dataType?.id || -1),
      selectedValidityTypeId: computed(() => packageModel.validity?.id || -1),
      selectedPurchaseTypeId: computed(
        () => packageModel.purchaseType?.id || -1
      ),
      currentZoneName: computed(() => packageModel.currentZoneName || ""),
      eSimTypes,
      showField,

      selectDataType(dataType: IDataType) {
        setDirectoryData("dataType", dataType);
      },

      selectPurchaseType(purchaseType: IPurchaseType) {
        setDirectoryData("purchaseType", purchaseType);
      },

      selectValidityType(validityType: IValidity) {
        setDirectoryData("validity", validityType);
      },

      selectBundle(id: number) {
        setDirectoryData("bundleId", id);
      },

      selectTraffic(id: number) {
        packageModel.traffic =
          trafficList.value.find(el => el.id === id) ?? props.trafficList[0];
      },

      changeDiscountAvailable({ target }: Event) {
        discountAvailable.value = (target as HTMLInputElement).checked || false;
      },

      selectPlan(id: number): void {
        packageModel.currentPlans.forEach((plan: IOptionsPlan) => {
          plan.selected = plan.id === id;
        });
      },

      selectEsimType(id: number): void {
        eSimTypes.value.forEach((type: IOptionsPlan) => {
          type.selected = type.id === id;
        });
      },

      selectProvider(id: number) {
        setDirectoryData("bundleId", -1);
        eSimTypes.value.forEach((type: IOptionsPlan) => {
          type.selected = type.id === 1;
        });
        packageModel.providerType =
          providers.value.find(el => el.id === id) ?? props.providers?.[0];
      }
    };
  },

  data(): {
    tabs: string[];
    selectedTab: number;
    validationError: boolean;
    modalLoading: boolean;
    beforeEnter: Function;
    parentTouched: boolean;
    enter: Function;
    leave: Function;
    tabsList: ITab[];
  } {
    return {
      ...useCollapseAnimation,
      validationError: false,
      selectedTab: 0,
      modalLoading: false,
      parentTouched: false,
      tabs,
      tabsList: tabs.map((title, idx) => {
        return { title, id: idx };
      })
    };
  },

  computed: {
    title() {
      return this.isEdit ? "Edit package" : "Add package";
    },

    submitError() {
      if (this.localDefaultPrice === "Error") {
        return true;
      }
      return this.validationError;
    },

    updatedOperators() {
      return this.operators.map((operator: IPlanOperator) => {
        return {
          ...operator,
          networkGenerationString: networkGenerationsToString(
            operator.operator.netGens
          )
        };
      });
    },

    getLabelForInput() {
      return this.packageModel.selectedPlan.discount
        ? "Price with discount in $"
        : "Default price in $";
    }
  },

  watch: {
    opened: {
      handler(opened: boolean) {
        if (!opened) {
          this.selectedTab = 0;
        }
      }
    }
  },

  methods: {
    hide() {
      this.$emit("hide");
    },

    onChangeErrorState(error: boolean) {
      this.validationError = error;
    },

    submitHandler() {
      if (!this.validationError) {
        const eSimTypeId = this.eSimTypes.find(t => t.selected)?.id;
        this.$emit("submit", this.packageModel, eSimTypeId);
      }
    }
  }
});
